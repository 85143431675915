import axios from "@/config/http";
export const orderReviewApi = {
  getBaseData(params) {
    return axios.get("/mall/order/getChecklist", { params });
  },
  // 获取订单审核模态框数据
  getOrderById(params) {
    return axios.get("/mall/order/getOrderById", { params });
  },
  // 获取订单选品数据
  getGoodinfoData(params) {
    return axios.get("/mall/order/getOrderBySku", { params });
  },
  // 待审核数据提交
  ToBeReviewed(data) {
    return axios.put("/mall/order/orderTakeEffect", data);
  },
  // 制定计划数据查询
  eanctPlanGet(params) {
    return axios.get("/mall/plan/getPlanDetails", { params });
  },
  // 制定计划数据提交
  eanctPlanSUb(data) {
    return axios.post("/mall/plan/eanctPlan", data);
  },
  // 获取进度反馈数据/mall/plan/progressFeedback
  progressBack(params) {
    return axios.get("/mall/plan/eanctPlanDetails", { params });
  },
  // 进度反馈提交
  progressFeedback(data) {
    return axios.put("/mall/plan/progressFeedback", data);
  },
  //结单数据查询/mall/plan/getOverMonad
  getOverMonad(params) {
    return axios.get("/mall/plan/getOverMonad", { params });
  },
  // 结单数据提交/mall/plan/overMonadUpdate
  overMonadUpdate(data) {
    return axios.put("/mall/plan/overMonadUpdate", data);
  },
  // 订单检测-详情获取数据/mall/plan/orderDetection
  getDetection(params) {
    return axios.get("/mall/plan/orderTrace", { params });
  },
  // 订单检测获取每日数据
  getorderDetection(params) {
    return axios.get("/mall/plan/getorderDetection", { params });
  },
  // 获取详情数据
  getDetailData(params) {
    return axios.get("/mall/order/getTrade", { params });
  },
  // 结单凭证提交/mall/plan/overMonadUpdate
  submiMonadUpdate(data) {
    return axios.put("/mall/plan/overMonadUpdate", data);
  },
  // 以生效结单提交/mall/plan/overMonadUpdate
  submitMonadUpdate(data) {
    return axios.put("/mall/plan/overMonadUpdate", data);
  },
  // wu/mall/order/cancelOrder
  cancelOrder(id) {
    return axios.put(`/mall/order/cancelOrder/${id}`);
  },

  // 各个订单状态数量数组
  async tabsNum() {
    return await axios.get(`/mall/order/getOrderListData`);
  },
  // 终止订单接口
  stoporder(data){
    return axios.post('/mall/order/endOrder',data)
  },
  // 获取订单信息
  getdingdandetail(params){
    return axios.get('/mall/plan/orderProductionDetail',{params})
  },
  // 获取禁用日期
  getcheckOrder(data){
    return axios.get('/planPostpone/checkOrderDateRange/'+data)
  }
};
