<template>
  <div>
    <a-modal
      title="进度反馈"
      :visible="config.visible"
      @cancel="handleCancel"
      :footer="null"
      width="850px"
    >
      <a-form-model
        :model="form"
        :rules="rules"
        ref="form"
        :disabled="true"
        style="display: flex; flex-wrap: wrap"
      >
        <a-form-model-item
          class="alignment twoLine"
          label="订购数量："
          prop="goods_num"
        >
          <a-input
            placeholder="请输入"
            :disabled="true"
            v-model="config.data.goodsNum"
          />
        </a-form-model-item>
        <a-form-model-item
          class="alignment twoLine"
          label="交货日期："
          prop="time_delivery"
        >
          <a-input
            placeholder="请输入"
            :disabled="true"
            v-model="config.data.timeDelivery"
          />
        </a-form-model-item>
        <a-form-model-item
          class="alignment twoLine"
          label="生产开始日："
          prop="goods_name_cn"
        >
          <a-input
            placeholder="请输入"
            :disabled="true"
            v-model="config.data.startTime"
          />
        </a-form-model-item>
        <a-form-model-item
          class="alignment twoLine"
          label="生产结束日："
          prop="goods_name_cn"
        >
          <a-input
            placeholder="请输入"
            :disabled="true"
            v-model="config.data.achieveTime"
          />
        </a-form-model-item>
      </a-form-model>
      <a-table :columns="columns" :data-source="data" :pagination="false">
        <!-- 表格标题 -->
        <span slot="actuallyTitle">
          <span style="color: red">*</span>
          实际完成数
        </span>
        <span slot="BadGoogsTitle">
          <span style="color: red">*</span>
          不良数
        </span>
        <template slot="quantity" slot-scope="text, record">
          <div>
            <numberInput
              v-model="record.badnessComplete"
              :disabled="!record.disabled"
              :config="badConfig"
            />
            <!-- <a-input v-model="record.badnessComplete"> </a-input> -->
          </div>
        </template>
        <template slot="actually" slot-scope="text, record">
          <!-- <a-input v-model="record.realityComplete" /> -->
          <numberInput
            v-model="record.realityComplete"
            :disabled="!record.disabled"
            :config="badConfig"
          />
        </template>
        <template slot="picture" slot-scope="text, record">
          <!-- <a-upload
            list-type="picture-card"
            accept=".png,.jpg,.jpeg"
            :show-upload-list="false"
          >
            <div>
              <a-icon type="plus" />
              <div class="ant-upload-text">Upload</div>
            </div>
          </a-upload> -->
          <uploadImage v-model="record.reports" :disabled="!record.disabled" />
        </template>
      </a-table>
      <div style="text-align: right; margin-top: 20px">
        <a-button @click="closeModel" class="btn-item">返回 </a-button>
        <a-popconfirm ok-text="确定" cancel-text="取消" @confirm="confirm">
          <template slot="title">
            <div>是否确认提交订单 ?</div>
          </template>
          <a-button class="xp-btn-color" v-if="buttonVisible('提交')">
            提交
          </a-button>
        </a-popconfirm>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { rules } from "@/utils/rules.js";
import moment from "moment";
import { orderReviewApi } from "@/api/orderReview.js";
import { tips } from "@/utils/tips.js";
import uploadImage from "@/components/uploadImage";
import numberInput from "@/components/numberInput";
// 按钮权限代码
import { buttonVisible } from "@/utils/btn.js";
const columns = [
  {
    title: "生产日期",
    dataIndex: "productionData",
    key: "productionData",
    width: 120,
  },
  {
    title: "计划完成数",
    dataIndex: "planComplete",
    width: 170,
  },
  {
    dataIndex: "realityComplete",
    slots: { title: "actuallyTitle" },
    scopedSlots: { customRender: "actually" },
  },
  {
    slots: { title: "BadGoogsTitle" },
    dataIndex: "badnessComplete",
    scopedSlots: { customRender: "quantity" },
  },
  {
    title: "质检报告",
    dataIndex: "reports",
    width: 170,
    scopedSlots: { customRender: "picture" },
  },
];

const data = [];
export default {
  props: {
    config: {
      type: Object,
      default() {
        return {
          visible: false,
          data: {},
          orderId: "",
          otherDate:[] //其他禁止时间
        };
      },
    },
  },
  data() {
    return {
      form: {
        goods_num: "", // 订购数量
        time_delivery: "", //交货日期
      },
      rules,
      data,
      columns,
      badConfig: {
        reg: /^[0-9]\d*$/,
        msg: "值输入最小值为0的正整数",
      },
    };
  },
  methods: {
    // 判断按钮在这个角色权限下是否显示
    buttonVisible,
    handleCancel() {
      this.config.visible = false;
    },
    closeModel() {
      this.config.visible = false;
    },
    moment,
    async confirm() {
      // let target = this.data.some((v) => {
      //   return !v.realityComplete || !v.realityComplete;
      // });
      // if (target) return this.$message.error("进度反馈中必填项未填写");
      let targetData = this.data.filter((v) => {
        return v.disabled;
      });
      let isok = targetData.some((v) => {
        return !v.realityComplete || !v.badnessComplete;
      });
      if (isok) return this.$message.error("进度反馈数据未填写完整");
      let data = {
        orderId: this.config.orderId,
        planFeedbackDTOS: targetData,
      };
      let res = await orderReviewApi.progressFeedback(data);
      tips(res, "修改进度反馈");
      if (res.success) {
        this.$parent.getBaseData();
        this.closeModel();
      }
    },
    // 传入两个时间，如果第一个时间打返回false,第二个大返回true
    Comparatively(data1, record = { a: "1" }, text = ["a"]) {
      // 如果时间在禁止填入数组中returen false
      if(Array.isArray(this.config.otherDate) && this.config.otherDate.includes(data1)){
        return false
      }
      // 获取当前年月日并把时分秒改为12点这个为目标时间
      let arr = data1.split("-");
      let yerar = new Date().getFullYear();
      let moth = new Date().getMonth() + 1 + "";
      moth = moth.padStart(2, "0");
      let day = new Date().getDate() + "";
      day = day.padStart(2, "0");
      let h = "12";
      let m = "00";

      // 当前日期的十二点（时间戳）
      let target = new Date(yerar, Number(moth) - 1, day, h, m, "00");
      // 获得当前时间
      let currentTime = new Date();
      // 获得昨天的时间
      if (
        new Date(arr[0], arr[1] + 1, arr[2]) < new Date(yerar, moth + 1, day)
        // (data1 === yerar + "-" + moth + "-" + day && currentTime >= target)
      ) {
        let target1 = text.every((v) => {
          return record[v];
        });
        if (target1 == false) {
          return !target1;
        }
      }

      if (data1 === yerar + "-" + moth + "-" + day - 1) {
        // 当前时间小于十二点
        if (currentTime < target) {
          return true;
        }
      }
      if (data1 === yerar + "-" + moth + "-" + day) {
        // 这是今天
        if (currentTime > target) {
          let target1 = text.every((v) => {
            // 如果时null就直接返回false
            if (record[v] === null) return false;
            return record[v] + "";
          });
          return !target1;
        }
      }
      // 获得明天的时间
      return false;
    },
    // 如果小于昨天
  },
  created() {
    // 设置禁用属性
    this.data = this.config.data.planVOS;
    this.data.forEach((v) => {
      v.disabled = this.Comparatively(v.productionData, v, [
        "realityComplete",
        "badnessComplete",
      ]);
    });
  },
  components: {
    uploadImage,
    numberInput,
  },
};
</script>

<style scoped lang="scss">
.alignment {
  display: flex;
  /deep/.ant-form-item-label {
    width: 100px !important;
    label {
      white-space: initial;
    }
  }
  .ant-form-item-control-wrapper {
    flex: 1;
  }
}
.btn-item {
  margin-right: 10px;
}
.twoLine {
  width: 50%;
}
</style>
