<template>
  <div>
    <a-modal
      :visible="config.visible"
      @cancel="handleCancel"
      :footer="null"
      width="750px"
    >
      <template #title>
        <div class="titleBox">
          <p>终止</p>
          <p class="des">|</p>
          <p class="des">
            <span> 订单编号：<br /> </span>
            {{ form.orderSn }}
          </p>
        </div>
      </template>
      <a-form-model
        :model="form"
        :rules="rules"
        ref="form"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 12 }"
      >
        <a-form-model-item label="订单完成数量" prop="orderComplete">
          <numberInput
            :config="{ reg: /^[0-9]\d*$/, msg: '请输入最小值为0的正整数' }"
            v-model="form.orderComplete"
          />
        </a-form-model-item>
        <a-form-model-item label="不良品数量" prop="orderBadness">
          <numberInput
            :config="{ reg: /^[0-9]\d*$/, msg: '请输入最小值为0的正整数' }"
            v-model="form.orderBadness"
          />
        </a-form-model-item>
        <a-form-model-item label="终止原因" prop="reason">
          <max-texarea
            placeholder="请输入"
            v-model="form.reason"
            :maxLength="128"
          />
        </a-form-model-item>
        <a-form-model-item label="上传附件" prop="fileList">
          <upload-file v-model="form.fileList" :config="fileConfig">
            <template #tip> 单个文件不能超过10M </template>
          </upload-file>
        </a-form-model-item>
      </a-form-model>
      <div style="text-align: right">
        <a-button class="btn-item" @click="handleCancel">返回 </a-button>
        <a-popconfirm ok-text="确定" cancel-text="取消" @confirm="confirm">
          <template slot="title">
            <div>是否确认提交订单 ?</div>
            <div>取消后不可修改</div>
          </template>
          <a-button class="xp-btn-color" v-if="buttonVisible('提交')">
            提交
          </a-button>
        </a-popconfirm>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { rules } from "@/utils/rules.js";
import { tips } from "@/utils/tips.js";
import MaxTexarea from "@/components/max-texarea/max-texarea.vue";
import UploadFile from "@/components/uploadFile";
import numberInput from "@/components/numberInput";
import { orderReviewApi } from "@/api/orderReview.js";
// 按钮权限代码
import { buttonVisible } from "@/utils/btn.js";
export default {
  props: {
    config: {
      type: Object,
      default() {
        return {
          visible: false,
          orderSn: "", //订单号,
          data: {},
        };
      },
    },
  },
  data() {
    return {
      form: {
        orderComplete: null, // 订单完成数
        orderBadness: null, //不良品数量
        reason: "", // 终止原因
        orderSn: null, // 订单号,
        fileList: [], //附件列表
      },
      rules,
      fileConfig: {
        maxLength: Infinity,
      },
    };
  },
  name: "stopMode",
  methods: {
    buttonVisible,
    handleCancel() {
      this.config.visible = false;
    },
    confirm() {
      this.$refs.form.validate(async (isok) => {
        if (isok) {
          let res = await orderReviewApi.stoporder(this.form);
          tips(res, "终止计划");
          if (res.success) {
            this.handleCancel();
            this.$parent.getBaseData();
          }
        }
      });
    },
  },
  created() {
    this.form.orderSn = this.config.orderSn;
    this.form.orderBadness = this.config.data.orderBadness;
    this.form.orderComplete = this.config.data.orderComplete;
  },
  components: {
    MaxTexarea,
    UploadFile,
    numberInput,
  },
};
</script>

<style scoped lang="scss">
.alignment {
  display: flex;
  /deep/.ant-form-item-label {
    width: 110px !important;
    text-align: right;
    label {
      white-space: initial;
    }
  }
  .ant-form-item-control-wrapper {
    flex: 1;
  }
}
.btn-item {
  margin-right: 10px;
}
.oneline {
  width: 50%;
}
.ant-table-wrapper {
  margin-bottom: 20px;
}
.titleBox {
  display: flex;
  .des {
    margin-left: 10px;
    font-size: 14px;
    color: #ccc;
  }
}
</style>
