<template>
  <div>
    <a-modal
      title="审核"
      :visible="config.visible"
      @cancel="handleCancel"
      :footer="null"
      width="854px"
    >
      <div class="examine-box">
        <div style="width: 100%; margin-bottom: 15px">
          <span style="color: #ff4600">▋</span> <span>订单信息 </span>
        </div>
        <div class="examine-item">
          <span> 型号：</span
          ><span class="examine-item-text" :title="config.info.skuType"
            >{{ config.info.skuType }}
          </span>
        </div>
        <div class="examine-item">
          <span> 意向来源：</span><span> {{ config.info.recycleName }} </span>
        </div>
        <div class="examine-item">
          <span> 颜色：</span
          ><span class="examine-item-text" :title="config.info.skuColoer">
            {{ config.info.skuColoer }}
          </span>
        </div>
        <div class="examine-item">
          <!-- <span> 交货日期：</span> -->
          <a-form-model :model="form" :rules="rules" ref="form1">
            <a-form-model-item
              class="alignmentExamine1 oneline"
              label="交货日期:"
              prop="timeDelivery"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-date-picker
                :allowClear="false"
                v-model="form.timeDelivery"
                :default-value="
                  form.timeDelivery
                    ? moment(form.timeDelivery, 'YYYY-MM-DD')
                    : ''
                "
                format="YYYY-MM-DD"
              />
            </a-form-model-item>
          </a-form-model>
        </div>
        <div class="examine-item" style="margin-top: 20px">
          <span> 订购数量：</span><span>{{ config.info.goodsNum }} </span>
        </div>
        <div class="examine-item" style="margin-top: 20px">
          <span> 交货地点：</span
          ><span class="examine-item-text" :title="config.info.placeDelivery">
            {{ config.info.placeDelivery }}
          </span>
        </div>
        <!-- <div class="examine-item">

        </div> -->
      </div>
      <a-form-model :model="form" :rules="rules" ref="form">
        <a-form-model-item
          class="alignmentExamine oneline"
          label="基础价:"
          prop="onPrice"
        >
          <!-- <numberInput v-model="form.onPrice" placeholder="请输入" /> -->
          <a-input-number
            v-model="form.onPrice"
            style="width: 100%"
            :min="0.01"
            :precision="2"
            placeholder="请输入"
            @change="handleTotalPrice"
          ></a-input-number>
        </a-form-model-item>
        <div style="width: 100%; margin-bottom: 15px">
          <span style="color: #ff4600">▋</span> <span>审核信息 </span>
        </div>
        <a-form-model-item
          class="alignmentExamine oneline"
          label="合同总额  :"
          prop="contractPrice"
        >
          <!-- <numberInput placeholder="请输入" v-model="form.contractPrice" /> -->
          <a-input-number
            v-model="form.contractPrice"
            style="width: 100%"
            :min="0.01"
            :precision="2"
            placeholder="请输入"
          ></a-input-number>
        </a-form-model-item>
        <a-form-model-item
          class="alignmentExamine oneline"
          label="双边合同  :"
          prop="contractFiles"
        >
          <uploadFile v-model="form.contractFiles" />
          <span style="color: rgba(51, 51, 51, 0.65)">
            仅支持pdf格式，大小不能超过10M
          </span>
        </a-form-model-item>
        <a-form-model-item
          class="alignmentExamine oneline"
          label="预付款凭证"
          prop="paymentVouchers"
          style="width: 70%"
        >
          <span style="color: rgba(51, 51, 51, 0.65)" class="tips"
            >支持.jpg .png 格式，最多不超过2张</span
          >
          <uploadImage
            v-model="form.paymentVouchers"
            :config="paymentVouchersConfig"
            @blur="handleBlur('paymentVouchers')"
          />
        </a-form-model-item>
      </a-form-model>
      <div style="text-align: right">
        <a-button class="btn-item" @click="handleCancel">返回 </a-button>
        <a-popconfirm
          title="是否确认暂存订单?"
          ok-text="确定"
          cancel-text="取消"
          @confirm="TemporaryData(3)"
        >
          <a-button class="btn-item" v-if="buttonVisible('暂存')"
            >暂存
          </a-button>
        </a-popconfirm>
        <a-button
          @click="TemporaryData(1)"
          class="btn-item"
          v-if="buttonVisible('取消订单')"
          >取消订单
        </a-button>
        <a-popconfirm
          title="是否确认生效订单 ?"
          ok-text="确定"
          cancel-text="取消"
          @confirm="TemporaryData(2)"
        >
          <a-button class="xp-btn-color" v-if="buttonVisible('生效订单')">
            生效订单
          </a-button>
        </a-popconfirm>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { rules } from "@/utils/rules.js";
import uploadImage from "@/components/uploadImage";
import uploadFile from "@/components/uploadFile";
import { orderReviewApi } from "@/api/orderReview.js";
import { tips } from "@/utils/tips.js";
// import numberInput from "@/components/numberInput";
import moment from "moment";
// 按钮权限代码
import { buttonVisible } from "@/utils/btn.js";

export default {
  name: "examineModel",
  props: {
    config: {
      type: Object,
      default() {
        return {
          visible: false,
          info: {}, //审核数据
        };
      },
    },
  },
  data() {
    return {
      rules,
      form: {
        contractPrice: "", //合同总额
        contractFiles: [], //双边合同
        paymentVouchers: [], //预付款凭证
        timeDelivery: "", //交货时间
        onPrice: undefined,
      },
      paymentVouchersConfig: {
        maxLength: 2, //20张
        maxSize: 10, //20M
      },
      formItemLayout: {
        labelCol: { span: 3 },
        wrapperCol: { span: 16 },
      },
    };
  },
  methods: {
    moment,
    // 判断按钮在这个角色权限下是否显示
    buttonVisible,
    handleCancel() {
      this.config.visible = false;
    },
    handleChange() {},
    confirm() {},
    async TemporaryData(orderStatus) {
      if (orderStatus == 1) {
        let msg = "取消";
        let res = await orderReviewApi.cancelOrder(this.config.orderId);
        if (res.success) {
          this.$parent.getBaseData();
          this.handleCancel();
        }
        return tips(res, msg);
      }
      this.$refs.form.validate((isOk1) => {
        if (isOk1) {
          this.$refs.form1.validate(async (isOk) => {
            if (isOk) {
              let data = {
                ...this.form,
                orderStatus,
                orderId: this.config.orderId,
                timeDelivery: moment(this.form.timeDelivery).format(
                  "YYYY-MM-DD"
                ),
              };
              let msg = orderStatus == 2 ? "生效" : "暂存";
              let res = await orderReviewApi.ToBeReviewed(data);
              if (res.success) {
                this.$parent.getBaseData();
                this.handleCancel();
              }
              tips(res, msg);
            }
          });
        }
      });
    },
    handleBlur(data) {
      this.$refs.form.validateField(data);
    },

    // 动态算合同总额
    handleTotalPrice() {
      this.form.contractPrice = (
        this.config.info.goodsNum * this.form.onPrice
      ).toFixed(2);
    },
  },
  created() {
    this.form.contractPrice = this.config.info.contractPrice;
    this.form.timeDelivery = this.config.info.timeDelivery;
    this.form.onPrice = this.config.info.onPrice;
    this.form.contractFiles = this.config.info.contractFiles
      ? this.config.info.contractFiles
      : [];
    this.form.paymentVouchers = this.config.info.paymentVouchers
      ? this.config.info.paymentVouchers
      : [];
  },
  components: {
    uploadImage,
    uploadFile,
    // numberInput,
  },
};
</script>

<style scoped lang="scss">
.examine-box {
  display: flex;
  flex-wrap: wrap;
  .examine-item {
    width: 50%;
    height: 40px;
    .examine-item-text {
      display: inline-block;
      color: #333333;
      width: 20rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: bottom;
    }
  }
}
.alignmentExamine {
  display: flex;
  /deep/.ant-form-item-label {
    width: 115px;
    text-align: left;
    label {
      white-space: initial;
    }
  }
}
.alignmentExamine1 {
  display: flex;
  /deep/.ant-form-item-label {
    width: 84px;
    text-align: left;
    label {
      white-space: initial;
    }
  }
}
/deep/.ant-form-item-control-wrapper {
  width: 100%;
}
.oneline {
  width: 50%;
}
.btn-item {
  margin-right: 10px;
}
.tips {
  position: relative;
  white-space: nowrap;
  color: #333333;
}
</style>
