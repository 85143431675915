<template>
  <div>
    <div class="search-box">
      <div class="lable-box search-item">
        <label> 订单编号： </label>
        <a-input v-model="searchText.orderSn" placeholder="请输入" />
      </div>
      <div class="lable-box search-item">
        <label> 下单时间：&nbsp; </label>
        <a-range-picker
          v-model="times"
          format="YYYY-MM-DD HH:mm:ss"
          showTime
          style="width: 100%"
          dropdownClassName="hiddenS"
        />
      </div>
      <div class="lable-box search-item">
        <label>意向来源 ：</label>
        <a-input v-model="searchText.recycleName" placeholder="请输入" />
      </div>
      <div class="lable-box search-item">
        <label> 商品名称： </label>
        <a-input v-model="searchText.goodsName" placeholder="请输入" />
      </div>
      <div class="search-item">
        <a-button class="button-item" @click="handleReset"> 重置 </a-button>
        <a-button class="button-item" @click="search"> 查询 </a-button>
      </div>
    </div>
    <goodInfo v-for="(item, index) in dataList" :key="index" :config="item">
      <template #statutext>
        <span style="color: #b1aca9">已终止</span>
      </template>
      <template #control>
        <a-button
          class="control"
          style="width: 98px; height: 35px"
          @click="handleVDetail(item)"
          >详情</a-button
        >
      </template>
    </goodInfo>
    <voucher-detail-model
      :config="vDetailConfig"
      v-if="vDetailConfig.visible"
    />
    <a-pagination
      class="pagenation"
      v-model="pagenation.page"
      :total="pagenation.total"
      show-less-items
      @change="changePageNation"
    />
    <!-- <addTableModel :config="editConfig" v-if="editConfig.visible">
      <template #footer>
        <div style="text-align: right">
          <a-button @click="editConfig.visible = false" style="margin: 0 10px"
            >关闭</a-button
          >
        </div>
      </template>
    </addTableModel> -->
  </div>
</template>

<script>
import goodInfo from "@/components/goodInfo/goodInfo.vue";
// import AddTableModel from "@/components/addTableModel/addTableModel";
import { orderReviewApi } from "@/api/orderReview.js";
import { loading } from "@/utils/tips.js";
import { shelfApplicaAPi } from "@/api/ShelfApplication.js";
import moment from "moment";
import VoucherDetailModel from "./component/voucherDetailModel.vue";
export default {
  components: {
    goodInfo,
    // AddTableModel,
    VoucherDetailModel
  },
  data() {
    return {
      goodConfig: {
        visible: false,
      },
      pagenation: {
        size: 10,
        page: 1,
        total: 100,
        orderStatus: 4,
      },
      dataList: [],
      editConfig: {
        visible: false,
      },
      searchText: {
        orderSn: "", //订单号
        recycleName: "",
        goodsName: "",
        // orderTakeState: undefined, // 已生效订单状态
      },
      times: [],
      vDetailConfig: {
        terminated: true,
        visible: false,
      },
    };
  },
  methods: {
    async handleGood(data) {
      let target = {
        GoodsId: data.goodsId,
      };
      let res = await shelfApplicaAPi.geyEditData(target);
      if (res.success) {
        let obj = {
          visible: true,
          data: res.data,
          type: "uninput",
          title: "详情",
        };
        this.editConfig = obj;
      }
    },
    handleExamine() {
      this.examineConfig.visible = true;
    },
    async handleVDetail(item) {
      let data = { orderId: item.orderId };
      let res = await orderReviewApi.getDetailData(data);
      this.vDetailConfig.data = res.data;
      this.vDetailConfig.visible = true;
    },
    async getBaseData() {
      this.$emit("pullNum");
      loading.show();
      let data = {
        ...this.pagenation,
        ...this.searchText,
        orderStartTime: this.times[0]
          ? moment(this.times[0]).format("yyyy-MM-DD HH:mm:ss")
          : undefined,
        orderEndTime: this.times[1]
          ? moment(this.times[1]).format("yyyy-MM-DD HH:mm:ss")
          : undefined,
      };
      try {
        let res = await orderReviewApi.getBaseData(data);
        if (res.success) {
          this.dataList = res.data.records;
          this.pagenation.total = res.data.total;
        }
        loading.close();
      } catch {
        loading.close();
      }
    },
    changePageNation() {
      this.getBaseData();
    },
    handleReset() {
      this.pagenation.page = 1;
      this.searchText = {
        orderSn: "", //订单号
        recycleName: "",
        goodsName: "",
        // orderTakeState: undefined, // 已生效订单状态
      };
      this.times = [];
      this.getBaseData();
    },
    search() {
      this.pagenation.page = 1;
      this.getBaseData();
    },
  },
  mounted() {
    this.getBaseData();
  },
};
</script>

<style scoped lang="scss">
.pagenation {
  margin-top: 20px;
  text-align: right;
  margin-bottom: 40px;
  margin-right: 30px;
}
.search-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .search-item {
    width: 30%;
    text-align: right;
  }
  .button-item {
    margin-left: 10px;
    &:nth-child(2) {
      background-color: #ff4600;
      color: #fff;
    }
  }
}
</style>
