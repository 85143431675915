<template>
  <div class="max-texarea-box">
    <a-textarea
      :value="value"
      :maxLength="maxLength"
      :auto-size="autoSize"
      :placeholder="placeholder"
      @change="emitChange"
      @pressEnter="pressEnter"
      @blur="emitBlur"
      v-bind="$attrs"
      @input="changeValue"
    />
    <span class="computedLength">已输入{{ length }}/{{ maxLength }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: "",
    },
    maxLength: {
      type: Number,
      default: 100,
    },
    autoSize: {
      type: Object,
      default: () => {
        return {
          minRows: 6,
          maxRows: 6,
        };
      },
    },
    placeholder: {
      type: String,
      defaul: "输入内容",
    },
  },
  computed: {
    length() {
      return this.value?.length;
    },
  },
  methods: {
    changeValue(e) {
      this.$emit("input", e.target.value);
    },
    emitChange(e) {
      this.$emit("change", e.target.value);
    },
    pressEnter(e) {
      this.$emit("pressEnter", e);
    },
    emitBlur(e) {
      this.$emit("blur", e);
    },
  },
};
</script>

<style scoped>
.max-texarea-box {
  position: relative;
}
.computedLength {
  position: absolute;
  display: block;
  padding-top: 1rem;
  font-size: 1rem;
  bottom: 0;
  right: 18px;
}
</style>