var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"goods-box"},[_c('div',{staticClass:"title-info"},[_c('div',{staticClass:"time"},[_c('p',{staticClass:"info-item"},[_vm._v(_vm._s(_vm.config.placeOrderTime))])]),_c('div',{staticClass:"code"},[_c('p',{staticClass:"info-item"},[_vm._v("订单编号 "+_vm._s(_vm.config.orderSn))]),(
          _vm.config.orderWarning == 0 &&
          _vm.config.orderTakeState == 1 &&
          _vm.config.orderStatus == 2
        )?_c('div',[_c('span',[_vm._v("订单预警：")]),_c('span',{staticClass:"green-icon"}),_c('span',{staticClass:"green"},[_vm._v("正常")])]):_vm._e(),(
          _vm.config.orderWarning == 1 &&
          _vm.config.orderTakeState == 1 &&
          _vm.config.orderStatus == 2
        )?_c('div',[_c('span',[_vm._v("订单预警：")]),_c('span',{staticClass:"red-icon"}),_c('span',{staticClass:"red"},[_vm._v("异常")])]):_vm._e()]),_c('div',[_vm._t("statutext")],2)]),_c('div',{staticClass:"contet-info"},[_c('div',[_c('img',{staticStyle:{"width":"120px"},attrs:{"src":_vm.config.skuImages}})]),_c('div',{staticClass:"goods-data"},[_c('h3',[_vm._v(_vm._s(_vm.config.goodsName))]),_c('p',{staticClass:"info-item"},[_vm._v(" 型号："),_c('span',{attrs:{"title":_vm.config.skuType}},[_vm._v(_vm._s(_vm.config.skuType))])]),_c('p',{staticClass:"info-item"},[_vm._v(" 颜色："),_c('span',{attrs:{"title":_vm.config.skuColoer}},[_vm._v(_vm._s(_vm.config.skuColoer))])]),_c('p',{staticClass:"info-item"},[_vm._v(" 订购数量："),_c('span',{attrs:{"title":_vm.config.goodsNum}},[_vm._v(_vm._s(_vm.config.goodsNum))])]),_c('p',{staticClass:"info-item"},[_vm._v(" 基础价(元)："),_c('span',{attrs:{"title":_vm.config.onPrice}},[_vm._v(_vm._s(_vm.config.onPrice))])])]),_c('div',{staticClass:"factory-data"},[_c('p',{staticClass:"info-item"},[_vm._v(" 意向来源："),_c('span',{staticStyle:{"color":"#ff4600"},attrs:{"title":_vm.config.recycleName}},[_vm._v(_vm._s(_vm.config.recycleName))])]),_c('p',{staticClass:"info-item"},[_vm._v(" 交货日期："),_c('span',{attrs:{"title":_vm.config.timeDelivery}},[_vm._v(_vm._s(_vm.config.timeDelivery))])]),_c('p',{staticClass:"info-item"},[_vm._v(" 交货地点："),_c('span',{attrs:{"title":_vm.config.placeDelivery}},[_vm._v(_vm._s(_vm.config.placeDelivery))])]),(_vm.config.orderStatus == 2)?_c('p',{staticClass:"info-item"},[_vm._v(" 合同额(元)： "),_c('span',{attrs:{"title":_vm.config.contractPrice}},[_vm._v(_vm._s(_vm.config.contractPrice))])]):_vm._e()]),_c('div',[_vm._t("control")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }