<template>
  <div>
    <a-modal
      title="详情"
      :visible="config.visible"
      @cancel="handleCancel"
      :footer="null"
      width="850px"
    >
      <div class="examine-box">
        <div style="width: 100%; margin-bottom: 15px">
          <span style="color: #ff4600">▋</span> <span>订单信息 </span>
        </div>
        <div class="examine-item">
          <span> 型号：</span><span> {{ config.data.skuType }} </span>
        </div>
        <div class="examine-item">
          <span> 意向来源：</span
          ><span style="color: rgba(255, 70, 0, 1)">
            {{ config.data.recycleName }}
          </span>
        </div>
        <div class="examine-item">
          <span> 颜色：</span><span> {{ config.data.skuColoer }} </span>
        </div>
        <div class="examine-item">
          <span> 交货日期：</span> <span>{{ config.data.timeDelivery }}</span>
        </div>
        <div class="examine-item">
          <span> 订购数量：</span> <span>{{ config.data.goodsNum }}</span>
        </div>
        <div class="examine-item">
          <span> 交货地址：</span> <span>{{ config.data.placeDelivery }}</span>
        </div>
        <div class="examine-item">
          <span> 基础价(元)：</span><span> {{ config.data.onPrice }} </span>
        </div>
      </div>

      <!-- 审核信息 -->
      <div style="width: 100%; margin-bottom: 15px">
        <span style="color: #ff4600">▋</span> <span>审核信息 </span>
      </div>
      <a-form-model :model="form" :rules="rules" ref="form">
        <a-form-model-item class="alignmentExamine oneline" label="合同总额  :">
          <a-input
            placeholder="请输入"
            :disabled="true"
            v-model="config.data.contractPrice"
          />
        </a-form-model-item>
        <a-form-model-item class="alignmentExamine oneline" label="双边合同  :">
          <div
            class="linkColor"
            v-for="(item, i) in config.data.contractFiles"
            :key="i"
          >
            <a :href="item" :title="item" :download="item">{{ item }}</a>
          </div>
        </a-form-model-item>
        <a-form-model-item class="alignmentExamine oneline" label="预付款凭证">
          <uploadImage v-model="config.data.paymentVouchers" :disabled="true" />
        </a-form-model-item>

        <a-form-model-item class="alignmentExamine oneline" label="结单凭证">
          <uploadImage
            v-model="config.data.overMonadVouchers"
            :disabled="true"
          />
        </a-form-model-item>

        <a-form-model-item class="alignmentExamine oneline" label="验货报告">
          <a
            class="linkColor"
            :href="config.data.examineReport"
            :title="config.data.examineReport"
            :download="config.data.examineReport"
            >{{ config.data.examineReport }}</a
          >
        </a-form-model-item>
      </a-form-model>

      <!-- 终止信息 -->
      <div class="examine-box" v-if="config.terminated">
        <div style="width: 100%; margin-bottom: 15px">
          <span style="color: #ff4600">▋</span> <span>终止信息 </span>
        </div>
        <div class="examine-item">
          <span> 订单完成数量：</span
          ><span> {{ config.data.orderComplete }} </span>
        </div>
        <div class="examine-item">
          <span> 不良品数量：</span> <span>{{ config.data.orderBadness }}</span>
        </div>
        <div class="examine-item">
          <span> 终止原因：</span> <span>{{ config.data.reason }}</span>
        </div>
        <div class="layout-inline">
          <label class="label">上传附件：</label>
          <div>
            <div
              class="linkColor"
              v-for="(item, i) in config.data.fileList"
              :key="i"
            >
              <a :href="item" :download="item">{{ item }}</a>
            </div>
          </div>
        </div>
      </div>

      <!-- 工厂详情 -->
      <div class="examine-box" style="padding-bottom: 40px">
        <div style="width: 100%; margin-bottom: 15px">
          <span style="color: #ff4600">▋</span> <span> 工厂详情 </span>
        </div>
        <div class="examine-item">
          <span> 工厂名称：</span
          ><span class="emplity" :title="config.data.factoryName">
            {{ config.data.factoryName }}
          </span>
        </div>
        <div class="examine-item">
          <span> 社会统一信用社代码：</span
          ><span class="emplity" :title="config.data.factoryCreditCode">
            {{ config.data.factoryCreditCode }}
          </span>
        </div>
        <div class="examine-item">
          <span> 工厂地址：</span
          ><span class="emplity" :title="config.data.factoryAddress">
            {{ config.data.factoryAddress }}
          </span>
        </div>
        <div class="examine-item">
          <span> 经营范围：</span>
          <span class="emplity" :title="config.data.manageScope">{{
            config.data.manageScope
          }}</span>
        </div>
        <div class="examine-item">
          <span> 工厂联系人：</span>
          <span class="emplity" :title="config.data.factoryLinkman">{{
            config.data.factoryLinkman
          }}</span>
        </div>
        <div class="examine-item">
          <span> 联系电话：</span
          ><span class="emplity" :title="config.data.factoryPhonenumber">
            {{ config.data.factoryPhonenumber }}
          </span>
        </div>
        <div
          class="examine-item"
          style="display: flex; position: relative; height: auto"
        >
          <span style="width: 93px"> 营业执照：</span>
          <uploadImage
            style="width: 80%"
            v-model="config.data.factoryLicenses"
            :disabled="true"
          />
        </div>
        <div class="examine-item">
          <span> 是否支持一件代发：</span>
          <a-radio-group :value="config.data.ifsend" disabled>
            <a-radio :value="true"> 是 </a-radio>
            <a-radio :value="false"> 否 </a-radio>
          </a-radio-group>
        </div>
      </div>
      <div style="text-align: right">
        <a-button @click="handleCancel" class="btn-item">返回 </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { rules } from "@/utils/rules.js";
import uploadImage from "@/components/uploadImage";
export default {
  name: "voucherDetailModel",
  props: {
    config: {
      type: Object,
      default() {
        return {
          visible: false,
          data: {},
        };
      },
    },
  },
  data() {
    return {
      rules,
      form: {},
      // value: 1,
    };
  },
  components: {
    uploadImage,
  },
  methods: {
    handleCancel() {
      this.config.visible = false;
    },
    handleChange() {},
    confirm() {},
    handleBlur(data) {
      this.$refs.form.validateField(data);
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
.examine-box {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  .examine-item {
    width: 50%;
    height: 40px;
    display: flex;
    span:nth-child(1) {
      display: block;
      width: 100px;
      text-align: right;
    }
  }
}
.alignmentExamine {
  display: flex;
  /deep/.ant-form-item-label {
    width: 100px;
    text-align: left;
    label {
      white-space: initial;
    }
  }
  .ant-form-item-control-wrapper {
    flex: 1;
  }
}
.oneline {
  width: 100%;
  /deep/.ant-form-item-control-wrapper {
    width: 80%;
    input {
      width: 50%;
    }
  }
}

.layout-inline {
  width: 100%;
  display: flex;
  position: relative;
  &.wrap {
    flex-wrap: wrap;
  }
}
.label {
  color: #616161;
  width: 100px;
  text-align: right;
}

.linkColor {
  color: #ff4600;
  width: 20rem;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}
.btn-item {
  margin-right: 10px;
}
.emplity {
  flex: 1;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
