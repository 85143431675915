<template>
  <div>
    <a-modal
      title="上传结单凭证"
      :visible="config.visible"
      @cancel="handleCancel"
      :footer="null"
      width="850px"
    >
      <div class="examine-box">
        <div style="width: 100%; margin-bottom: 15px">
          <span style="color: #ff4600">▋</span> <span>订单信息 </span>
        </div>
        <div class="examine-item">
          <span> 型号：</span><span> {{ config.data.skuType }} </span>
        </div>
        <div class="examine-item">
          <span> 意向来源：</span
          ><span style="color: #ff4600"> {{ config.data.recycleName }} </span>
        </div>
        <div class="examine-item">
          <span> 颜色：</span><span> {{ config.data.skuColoer }} </span>
        </div>
        <div class="examine-item">
          <span> 交货日期：</span> <span> {{ config.data.timeDelivery }} </span>
        </div>
        <div class="examine-item">
          <span> 基础价(元)：</span><span> {{ config.data.onPrice }} </span>
        </div>
      </div>
      <div style="width: 100%; margin-bottom: 15px">
        <span style="color: #ff4600">▋</span> <span>审核信息 </span>
      </div>
      <a-form-model :model="form" :rules="rules" ref="form">
        <a-form-model-item
          class="alignmentExamine oneline"
          label="合同总额  :"
          prop="contractPrice"
        >
          <a-input
            placeholder="请输入"
            :disabled="true"
            v-model="form.contractPrice"
          />
        </a-form-model-item>
        <a-form-model-item
          class="alignmentExamine oneline"
          label="双边合同  :"
          prop="contractFiles"
        >
          <!-- <uploadImage :disabled="true" v-model="form.contractFiles" /> -->
          <div
            class="linkColor"
            v-for="(item, i) in form.contractFiles"
            :key="i"
          >
            <a :href="item" :title="item" :download="item">{{ item }}</a>
          </div>
        </a-form-model-item>
        <a-form-model-item
          class="alignmentExamine oneline imagepos"
          label="预付款凭证"
          prop="paymentVouchers"
        >
          <uploadImage :disabled="true" v-model="form.paymentVouchers" />
        </a-form-model-item>

        <a-form-model-item
          class="alignmentExamine oneline imagepos"
          label="结单凭证"
          prop="overMonadVouchers"
        >
          <p style="color: rgba(51, 51, 51, 0.65)" class="tips">
            支持.jpg .png 格式，最多不超过2张
          </p>
          <uploadImage
            @blur="handleBlur('overMonadVouchers')"
            :config="imageConfig"
            v-model="form.overMonadVouchers"
          />
        </a-form-model-item>
        <a-form-model-item
          class="alignmentExamine oneline imagepos"
          label="验货报告"
          prop="examineReport"
        >
          <upload-file v-model="form.examineReport"> </upload-file>
          <!-- <p style="color: rgba(51, 51, 51, 0.65)" class="tips">
            支持.jpg .png 格式，最多不超过2张
          </p>
          <uploadImage
            @blur="handleBlur('overMonadVouchers')"
            :config="imageConfig"
            v-model="form.overMonadVouchers"
          /> -->
        </a-form-model-item>
      </a-form-model>
      <div style="text-align: right">
        <a-button @click="handleCancel" class="btn-item">返回 </a-button>
        <a-popconfirm
          title="是否确认暂存订单?"
          ok-text="确定"
          cancel-text="取消"
          @confirm="confirm(1)"
        >
          <a-button class="btn-item" v-if="buttonVisible('确认')"
            >确认
          </a-button>
        </a-popconfirm>
        <a-popconfirm
          title="是否确认生效订单 ?"
          ok-text="确定"
          cancel-text="取消"
          @confirm="confirm(2)"
        >
          <a-button class="xp-btn-color" v-if="buttonVisible('提交')">
            提交
          </a-button>
        </a-popconfirm>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { rules } from "@/utils/rules.js";
import uploadImage from "@/components/uploadImage";
import { orderReviewApi } from "@/api/orderReview.js";
import UploadFile from "@/components/uploadFile";
import { tips } from "@/utils/tips.js";
// 按钮权限代码
import { buttonVisible } from "@/utils/btn.js";
export default {
  name: "voucherModel",
  props: {
    config: {
      type: Object,
      default() {
        return {
          visible: false,
          data: {}, //数据
          orderId: "",
        };
      },
    },
  },
  data() {
    return {
      rules,
      form: {
        contractPrice: "",
        overMonadVouchers: [],
        examineReport: "",
        contractFiles: [],
        paymentVouchers: [],
      },
      imageConfig: {
        maxLength: 2, //20张
        maxSize: 10, //20M
      },
    };
  },
  methods: {
    // 判断按钮在这个角色权限下是否显示
    buttonVisible,
    handleCancel() {
      this.config.visible = false;
    },
    handleChange() {},
    confirm(data) {
      this.$refs.form.validate(async (isok) => {
        if (isok) {
          let traget = {
            overMonadVouchers: this.form.overMonadVouchers,
            examineReport: this.form.examineReport,
            orderId: this.config.orderId,
            monadState: data,
          };
          let res = await orderReviewApi.submitMonadUpdate(traget);
          let msg = data == 1 ? "暂存" : "提交";
          tips(res, msg);
          if (res.success) {
            this.handleCancel();
            this.$parent.getBaseData();
          }
        }
      });
    },
    handleBlur(data) {
      this.$refs.form.validateField(data);
    },
  },
  created() {
    this.form.contractPrice = this.config.data.contractPrice; //? this.config.data.contractPric: [];
    this.form.overMonadVouchers = this.config.data.overMonadVouchers
      ? this.config.data.overMonadVouchers
      : [];
    this.form.contractFiles = this.config.data.contractFiles
      ? this.config.data.contractFiles
      : [];
    this.form.paymentVouchers = this.config.data.paymentVouchers
      ? this.config.data.paymentVouchers
      : [];
    this.form.examineReport = this.config.data.examineReport;
  },
  components: {
    uploadImage,
    UploadFile,
  },
};
</script>

<style scoped lang="scss">
.examine-box {
  display: flex;
  flex-wrap: wrap;
  .examine-item {
    width: 50%;
    height: 40px;
  }
}
.alignmentExamine {
  display: flex;
  /deep/.ant-form-item-label {
    width: 100px;
    text-align: left;
    label {
      white-space: initial;
    }
  }
  .ant-form-item-control-wrapper {
    flex: 1;
  }
}
.oneline {
  width: 100%;
  /deep/.ant-form-item-control-wrapper {
    width: 80%;
    input {
      width: 50%;
    }
  }
}
.linkColor {
  color: #ff4600;
  width: 20rem;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}
.btn-item {
  margin-right: 10px;
}
.imagepos {
  label {
    top: -11px;
  }
}
</style>
