<template>
  <div>
    <a-modal
      title="制定计划"
      :visible="config.visible"
      @cancel="handleCancel"
      :footer="null"
      width="750px"
    >
      <a-form-model
        :model="form"
        :rules="rules"
        ref="form"
        style="display: flex; flex-wrap: wrap"
      >
        <a-form-model-item
          class="alignment oneline"
          label="订购数量："
          prop="goods_num"
        >
          <a-input
            placeholder="请输入"
            :disabled="true"
            v-model="config.data.goodsNum"
          />
        </a-form-model-item>
        <a-form-model-item
          class="alignment oneline"
          label="交货日期："
          prop="time_delivery"
        >
          <a-input
            placeholder="请输入"
            :disabled="true"
            v-model="config.data.timeDelivery"
          />
        </a-form-model-item>
        <a-form-model-item
          class="alignment required oneline"
          label="生产计划："
          prop="time_delivery"
        >
          <a-range-picker
            :disabled-date="disabledDate"
            format="YYYY-MM-DD"
            :allowClear="false"
            v-model="form.dateRange"
            @change="handleChange"
          />
        </a-form-model-item>
      </a-form-model>
      <a-table :columns="columns" :data-source="data" :pagination="false">
        <!-- 表格标题 -->
        <span slot="productionTitle">
          <span style="color: red">*</span>
          生产日
        </span>
        <span slot="quantityTitle">
          <span style="color: red">*</span>
          计划完成数
        </span>
        <template slot="quantity" slot-scope="text, record">
          <div>
            <!-- <a-input
              v-model="record.planComplete"
              :disabled="!record.productionDay"
            >
            </a-input> -->
            <numberInput
              v-model="record.planComplete"
              :disabled="!record.productionDay"
            />
          </div>
        </template>
        <template slot="IsProduction" slot-scope="text, record">
          <a-switch
            @change="
              (data) => {
                handlSwiper(data, record);
              }
            "
            v-model="record.productionDay"
          />
        </template>
      </a-table>
      <div style="text-align: right">
        <a-button class="btn-item" @click="handleCancel">返回 </a-button>
        <a-popconfirm ok-text="确定" cancel-text="取消" @confirm="confirm">
          <template slot="title">
            <div>是否确认提交订单 ?</div>
            <div>取消后不可修改</div>
          </template>
          <a-button class="xp-btn-color" v-if="buttonVisible('提交')">
            提交
          </a-button>
        </a-popconfirm>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { rules } from "@/utils/rules.js";
import { tips } from "@/utils/tips.js";
import moment from "moment";
import { orderReviewApi } from "@/api/orderReview.js";
import numberInput from "@/components/numberInput";
// 按钮权限代码
import { buttonVisible } from "@/utils/btn.js";

const columns = [
  {
    title: "生产日期",
    dataIndex: "productionData",
    key: "productionData",
  },
  {
    slots: { title: "productionTitle" },
    dataIndex: "productionDay",
    scopedSlots: { customRender: "IsProduction" },
    width: 250,
  },
  {
    slots: { title: "quantityTitle" },
    dataIndex: "planComplete",
    scopedSlots: { customRender: "quantity" },
    width: 250,
  },
];

const data = [];
export default {
  props: {
    config: {
      type: Object,
      default() {
        return {
          visible: false,
          orderId: "", //商品ID
          data: {}, //商品的模态框数据
        };
      },
    },
  },
  data() {
    return {
      form: {
        goods_num: "", // 订购数量
        time_delivery: "", //交货日期
        dateRange: [],
      },
      rules,
      data,
      columns,
    };
  },
  methods: {
    // 判断按钮在这个角色权限下是否显示
    buttonVisible,
    handleCancel() {
      this.config.visible = false;
    },
    async confirm() {
      if (this.data.length === 0) {
        return this.$message.error("生产计划未填写");
      }
      let target = this.data.some((v) => {
        // 如果是开始且每天完成数就为true
        if (v.productionDay && !v.planComplete) {
          return true;
        }
      });
      if (target) return this.$message.error("计划完成数未填写完整");
      let data = {
        achieveTime: moment(this.form.dateRange[1]).format("YYYY-MM-DD"),
        orderId: this.config.orderId,
        planDTOS: this.data,
        startTime: moment(this.form.dateRange[0]).format("YYYY-MM-DD"),
      };
      let res = await orderReviewApi.eanctPlanSUb(data);
      tips(res, "制定计划");
      if (res.success) {
        this.handleCancel();
        this.$parent.getBaseData();
      }
    },
    moment,
    handleChange(data) {
      let target = this.getDayNum(data[0], data[1]);
      let arr = [];
      for (let i = 0; i <= target; i++) {
        let obj = {
          productionData: moment(+data[0] + i * (24 * 3600 * 1000)).format(
            "YYYY-MM-DD"
          ),
          productionDay: true,
          planComplete: "",
          key: i,
        };
        arr.push(obj);
      }
      this.data = arr;
    },
    handlSwiper(data, record) {
      if (!data) {
        record.planComplete = "";
      }
    },
    getDayNum(startTime, endTime) {
      const stTime = Date.parse(new Date(startTime));
      const etTime = Date.parse(new Date(endTime));
      const usedTime = etTime - stTime;
      const days = Math.floor(usedTime / (24 * 3600 * 1000));
      return days;
    },
    disabledDate(current) {
      // Can not select days before today and today
      return (
        current &&
        current >= moment(this.config.data.timeDelivery).add(1, "days")
      );
    },
  },
  components: {
    numberInput,
  },
  created() {
    if (Object.keys(this.config.data).length) {
      let target = this.config.data;
      this.form.dateRange = [target.startTime, target.achieveTime];
      this.data = target.planVOS;
    }
  },
};
</script>

<style scoped lang="scss">
.alignment {
  display: flex;
  /deep/.ant-form-item-label {
    width: 110px !important;
    text-align: right;
    label {
      white-space: initial;
    }
  }
  .ant-form-item-control-wrapper {
    flex: 1;
  }
}
.btn-item {
  margin-right: 10px;
}
.oneline {
  width: 50%;
}
/deep/.required {
  label {
    &::before {
      display: inline-block;
      margin-right: 8 px;
      color: #f5222d;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
      position: relative;
      left: -5px;
    }
  }
}
.ant-table-wrapper {
  margin-bottom: 20px;
}
</style>
