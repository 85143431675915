<template>
  <div class="bottom-container">
    <a-tabs v-model="activeKey" @change="callback">
      <a-tab-pane key="1" :forceRender="true">
        <span slot="tab"
          >待审核 <span>({{ orderNum.examine }})</span></span
        >
        <await-reviewed v-if="activeKey == 1" @pullNum="getTabsNum" />
      </a-tab-pane>
      <a-tab-pane key="2" force-render>
        <span slot="tab"
          >已取消 <span>({{ orderNum.cancel }})</span></span
        >
        <canceled-model v-if="activeKey == 2" @pullNum="getTabsNum" />
      </a-tab-pane>
      <a-tab-pane key="3">
        <span slot="tab"
          >已生效 <span>({{ orderNum.pass }})</span></span
        >
        <effect-goods v-if="activeKey == 3" @pullNum="getTabsNum" />
      </a-tab-pane>
      <a-tab-pane key="4">
        <span slot="tab"
          >已终止 <span>({{ orderNum.stop }})</span></span
        >
        <terminated v-if="activeKey == 4" @pullNum="getTabsNum" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import AwaitReviewed from "./component/awaitReviewed.vue";
import EffectGoods from "./component/effectGoods.vue";
import CanceledModel from "./component/canceledModel.vue";
import terminated from "./component/terminated.vue";
import { orderReviewApi } from "@/api/orderReview.js";
export default {
  data() {
    return {
      activeKey: "1",
      orderNum: {
        examine: 0, // 审核
        cancel: 0, // 取消
        pass: 0, // 生效
        stop: 0, // 终止
      },
    };
  },
  methods: {
    callback() {
      this.getTabsNum();
    },
    // 拿取订单每个页签数量
    getTabsNum() {
      orderReviewApi.tabsNum().then((res) => {
        let i = 0;
        for (let item in this.orderNum) {
          this.orderNum[item] = res.data[i];
          i++;
        }
      });
    },
  },
  created() {
    this.getTabsNum();
  },
  components: {
    AwaitReviewed,
    EffectGoods,
    CanceledModel,
    terminated,
  },
};
</script>

<style lang="scss" scoped>
/deep/ .ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane {
  height: 630px;
  // padding-bottom: 40rem;
  overflow-y: scroll;
}
</style>
