export const btnAggregate = [
  {
    value: 1,
    data: ["暂存", "取消订单", "生效订单", "确认", "提交"],
  },
  {
    value: 3,
    data: ["暂存", "取消订单", "生效订单", "确认", "提交"],
  },
  {
    value: 4,
    data: ["暂存", "确认"],
  },
  {
    value: 5,
    data: [],
  },
];

// 合并权限按钮数组
export function mergeArr(roleIds, btnAggregate) {
  let arr = [];
  btnAggregate.forEach((item) => {
    if (roleIds.includes(item.value)) {
      arr = [...arr, ...item.data];
    }
  });
  return arr;
}

export function btnJurisdiction(type, arr) {
  return arr.includes(type);
}

export function buttonVisible(type) {
  console.log(type);
  let roleIds = this.$store.state.Login.roleIds;
  console.log(roleIds);
  let btnArr = mergeArr(roleIds, btnAggregate);
  let result = btnJurisdiction(type, btnArr);
  console.log("按钮显示", result);
  return result;
}
