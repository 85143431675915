<template>
  <div>
    <div>
      <goodInfo v-for="(item, index) in dataList" :key="index" :config="item">
        <template #statutext>
          <span style="color: #ff4600">待审核</span>
        </template>
        <template #control>
          <a-button
            class="xp-btn-color"
            style="width: 98px; height: 35px; margin-bottom: 12px"
            @click="handleExamine(item)"
            >审核</a-button
          >
          <a-button
            style="width: 98px; height: 35px; display: block"
            @click="handlegoodData(item)"
            >查看选品</a-button
          >
        </template>
      </goodInfo>
    </div>
    <awaitReviewed :config="examineConfig" v-if="examineConfig.visible" />
    <a-pagination
      class="pagenation"
      v-model="pagenation.page"
      :total="pagenation.total"
      @change="changePageNation"
      show-less-items
    />
    <addTableModel :config="editConfig" v-if="editConfig.visible">
      <template #titleText>
        <h3 style="margin-top: 1rem">是否检测：</h3>
        <a-radio-group v-model="isDetect" disabled>
          <a-radio :value="true"> 是 </a-radio>
          <a-radio :value="false"> 否 </a-radio>
        </a-radio-group>
      </template>
      <template #footer>
        <div style="text-align: right">
          <a-button @click="editConfig.visible = false" style="margin: 0 10px"
            >关闭</a-button
          >
        </div>
      </template>
    </addTableModel>
  </div>
</template>
<script>
import goodInfo from "@/components/goodInfo/goodInfo.vue";
import awaitReviewed from "./component/examineModel.vue";
import addTableModel from "@/components/addTableModel/addTableModel.vue";
import { orderReviewApi } from "@/api/orderReview.js";
import { loading } from "@/utils/tips.js";
import { shelfApplicaAPi } from "@/api/ShelfApplication.js";
export default {
  data() {
    return {
      examineConfig: {
        visible: false,
        info: {},
      },
      pagenation: {
        size: 10,
        page: 1,
        total: 100,
        orderStatus: 0,
      },
      dataList: [],
      editConfig: {},
      isDetect: false, // 是否检测
    };
  },
  components: {
    goodInfo,
    awaitReviewed,
    addTableModel,
  },
  methods: {
    async handleExamine(item) {
      let data = {
        orderId: item.orderId,
      };
      let res = await orderReviewApi.getOrderById(data);
      if (res.success) {
        this.examineConfig.visible = true;
        this.examineConfig.info = res.data;
        this.examineConfig.orderId = item.orderId;
      }
    },
    async getBaseData() {
      this.$emit("pullNum");
      loading.show();
      let data = { ...this.pagenation };
      try {
        let res = await orderReviewApi.getBaseData(data);
        if (res.success) {
          this.dataList = res.data.records;
          this.pagenation.total = res.data.total;
        }
        loading.close();
      } catch {
        loading.close();
      }
    },
    changePageNation() {
      this.getBaseData();
    },
    async handlegoodData(data) {
      let target = {
        GoodsId: data.goodsId,
      };
      let res = await shelfApplicaAPi.geyEditData(target);
      this.isDetect = res.data.isDetect;
      if (res.success) {
        let obj = {
          visible: true,
          data: res.data,
          type: "uninput",
          title: "详情",
        };
        this.editConfig = obj;
      }
    },
  },
  mounted() {
    this.getBaseData();
  },
};
</script>
<style lang="scss" scoped>
.pagenation {
  margin-top: 20px;
  text-align: right;
  margin-bottom: 40px;
  margin-right: 30px;
}
</style>
