<template>
  <div>
    <div class="search-box">
      <div class="lable-box search-item">
        <label> 订单编号： </label>
        <a-input v-model="searchText.orderSn" placeholder="请输入" />
      </div>
      <div class="lable-box search-item">
        <label> 下单时间：&nbsp; </label>
        <a-range-picker
          v-model="times"
          format="YYYY-MM-DD HH:mm:ss"
          showTime
          style="width: 100%"
          dropdownClassName="hiddenS"
        />
      </div>
      <div class="lable-box search-item">
        <label>意向来源 ：</label>
        <a-input v-model="searchText.recycleName" placeholder="请输入" />
      </div>
      <div class="lable-box search-item">
        <label> 商品名称： </label>
        <a-input v-model="searchText.goodsName" placeholder="请输入" />
      </div>
      <div class="lable-box search-item">
        <label>订单状态 ：</label>
        <a-select
          v-model="searchText.orderTakeState"
          style="width: 50%"
          placeholder="请选择"
        >
          <a-select-option value="0"> 生产准备阶段 </a-select-option>
          <a-select-option value="1"> 生产阶段 </a-select-option>
          <a-select-option value="2"> 验货阶段 </a-select-option>
          <a-select-option value="3"> 已结单 </a-select-option>
        </a-select>
      </div>
      <div class="search-item">
        <a-button class="button-item" @click="handleReset"> 重置 </a-button>
        <a-button class="button-item" @click="getBaseData(1)"> 查询 </a-button>
      </div>
    </div>
    <div class="good-content" v-for="(item, index) in dataList" :key="index">
      <goodInfo v-if="item.orderTakeState == 0" :config="item">
        <template #statutext>
          <span style="color: #faad14">生产准备阶段</span>
        </template>
        <template #control>
          <a-button
            class="xp-btn-color control"
            style="width: 98px; height: 35px"
            @click="handlePlan(item)"
            >制定计划</a-button
          >
          <a-button
            class="control"
            style="width: 98px; height: 35px"
            @click="handleVDetail(item)"
            >详情</a-button
          >
          <a-button
            class="control"
            style="width: 98px; height: 35px"
            @click="hadleStop(item)"
            >终止</a-button
          >
        </template>
      </goodInfo>
      <goodInfo v-if="item.orderTakeState == 1" :config="item">
        <template #statutext>
          <span style="color: #67aef3">生产阶段</span>
        </template>
        <template #control>
          <a-button
            class="xp-btn-color control"
            style="width: 98px; height: 35px"
            @click="handleProgress(item)"
            >进度反馈</a-button
          >
          <a-button
            class="control"
            style="width: 98px; height: 35px"
            @click="handletrack(item)"
            >订单监测</a-button
          >
          <a-button
            class="control"
            style="width: 98px; height: 35px"
            @click="handleVDetail(item)"
            >详情</a-button
          >
          <a-button
            class="control"
            style="width: 98px; height: 35px"
            @click="hadleStop(item)"
            >终止</a-button
          >
        </template>
      </goodInfo>
      <goodInfo v-if="item.orderTakeState == 2" :config="item">
        <template #statutext>
          <span style="color: #67aef3">验货阶段</span>
        </template>
        <template #control>
          <a-button
            class="xp-btn-color control"
            style="width: 98px; height: 35px"
            @click="handleVoucher(item)"
            >结单凭证</a-button
          >
          <a-button
            class="control"
            style="width: 98px; height: 35px"
            @click="handletrack(item)"
            >订单监测</a-button
          >
          <a-button
            class="control"
            style="width: 98px; height: 35px"
            @click="handleVDetail(item)"
            >详情</a-button
          >
          <a-button
            class="control"
            style="width: 98px; height: 35px"
            @click="hadleStop(item)"
            >终止</a-button
          >
        </template>
      </goodInfo>
      <goodInfo v-if="item.orderTakeState == 3" :config="item">
        <template #statutext>
          <span style="color: green">已结单</span>
        </template>
        <template #control>
          <a-button
            class="control"
            style="width: 98px; height: 35px"
            @click="handleVDetail(item)"
            >详情</a-button
          >
        </template>
      </goodInfo>
    </div>
    <a-pagination
      class="pagenation"
      v-model="pagenation.page"
      :total="pagenation.total"
      show-less-items
      @change="changePageNation"
    />
    <hade-plan-model :config="PlanConfig" v-if="PlanConfig.visible" />
    <progress-model :config="ProgressConfig" v-if="ProgressConfig.visible" />
    <track-model :config="trackConfig" v-if="trackConfig.visible" />
    <voucher-model :config="voucherConfig" v-if="voucherConfig.visible" />
    <voucher-detail-model
      :config="vDetailConfig"
      v-if="vDetailConfig.visible"
    />
    <stop-mode :config="stopConfig" v-if="stopConfig.visible" />
  </div>
</template>

<script>
import goodInfo from "@/components/goodInfo/goodInfo.vue";
import HadePlanModel from "./component/hadePlanModel.vue";
import StopMode from "./component/stopMode.vue";
import ProgressModel from "./component/ProgressModel.vue";
import TrackModel from "./component/trackModel.vue";
import VoucherModel from "./component/voucherModel.vue";
import VoucherDetailModel from "./component/voucherDetailModel.vue";
import { orderReviewApi } from "@/api/orderReview.js";
import { loading } from "@/utils/tips.js";
import moment from "moment";
export default {
  name: "effectGoods",
  data() {
    return {
      // 制定计划
      PlanConfig: {
        visible: false,
        orderId: "",
        data: {},
      },
      stopConfig: {
        visible: false,
        orderSn: "",
        data: {},
      },
      // 进度反馈
      ProgressConfig: {
        visible: false,
        data: {},
        orderId: "",
        otherDate:[] //其他禁用日器
      },
      trackConfig: {
        visible: false,
      },
      voucherConfig: {
        visible: false,
      },
      vDetailConfig: {
        visible: false,
      },
      pagenation: {
        size: 10,
        page: 1,
        total: 100,
        orderStatus: 2,
      },
      dataList: [],
      searchText: {
        orderSn: "", //订单号
        recycleName: "",
        goodsName: "",
        orderTakeState: undefined, // 已生效订单状态
      },
      times: [],
    };
  },
  methods: {
    // 模态框显示
    async handlePlan(item) {
      let data = { orderId: item.orderId };
      let res = await orderReviewApi.eanctPlanGet(data);
      if (res.success) {
        this.PlanConfig.visible = true;
        this.PlanConfig.orderId = item.orderId;
        this.PlanConfig.data = res.data[0];
      }
    },
    async handleProgress(item) {
      let data = { orderId: item.orderId };
      let res = await orderReviewApi.progressBack(data);
      let res1 = await orderReviewApi.getcheckOrder(item.orderId);
      if (res.success && res1.success) {
        res.data.planVOS.forEach((v) => {
          if (v.reports === null) {
            v.reports = "";
          }
        });
        this.ProgressConfig.orderId = item.orderId;
        this.ProgressConfig.data = res.data;
        this.ProgressConfig.otherDate = res1.data;
        this.ProgressConfig.visible = true;
      }
    },
    // 订单检测
    async handletrack(item) {
      let data = { orderId: item.orderId };
      let res = await orderReviewApi.getDetection(data);
      this.trackConfig.orderId = item.orderId;
      this.trackConfig.data = res.data;
      this.trackConfig.visible = true;
    },
    async handleVoucher(item) {
      let data = { orderId: item.orderId };
      let res = await orderReviewApi.getOverMonad(data);
      this.voucherConfig.orderId = item.orderId;
      this.voucherConfig.data = res.data;
      this.voucherConfig.visible = true;
    },
    async handleVDetail(item) {
      let data = { orderId: item.orderId };
      let res = await orderReviewApi.getDetailData(data);
      this.vDetailConfig.data = res.data;
      this.vDetailConfig.visible = true;
    },
    async hadleStop(item) {
      let data = { orderId: item.orderId };
      let res = await orderReviewApi.getdingdandetail(data);
      if (res.success) {
        this.stopConfig.data = res.data;
        this.stopConfig.visible = true;
        this.stopConfig.orderSn = item.orderSn;
      }
    },
    handleGood() {
      this.goodConfig.visible = true;
    },
    // 获取基本数据分页
    async getBaseData(pageNum) {
      this.$emit("pullNum");
      loading.show();
      if (pageNum) this.pagenation.page = 1;
      let data = {
        ...this.pagenation,
        ...this.searchText,
        orderStartTime: this.times[0]
          ? moment(this.times[0]).format("yyyy-MM-DD HH:mm:ss")
          : undefined,
        orderEndTime: this.times[1]
          ? moment(this.times[1]).format("yyyy-MM-DD HH:mm:ss")
          : undefined,
      };
      try {
        let res = await orderReviewApi.getBaseData(data);
        if (res.success) {
          this.dataList = res.data.records;
          this.pagenation.total = res.data.total;
        }
        loading.close();
      } catch {
        loading.close();
      }
    },
    changePageNation() {
      this.getBaseData();
    },
    // 重置按钮
    handleReset() {
      this.pagenation.page = 1;
      this.searchText = {
        orderSn: "", //订单号
        recycleName: "",
        goodsName: "",
        orderTakeState: undefined, // 已生效订单状态
      };
      this.times = [];
      this.getBaseData();
    },
  },
  mounted() {
    this.getBaseData();
  },
  components: {
    goodInfo,
    HadePlanModel,
    ProgressModel,
    TrackModel,
    VoucherModel,
    VoucherDetailModel,
    StopMode,
  },
};
</script>

<style lang="scss" scoped>
.search-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .search-item {
    width: 30%;
    text-align: right;
  }
  .button-item {
    margin-left: 10px;
    &:nth-child(2) {
      background-color: #ff4600;
      color: #fff;
    }
  }
}
.ant-table-wrapper {
  margin-top: 30px;
}
.good-content {
  margin-top: 20px;
}
.control {
  display: block;
  margin-bottom: 5px;
}
.pagenation {
  margin-top: 20px;
  text-align: right;
  margin-bottom: 40px;
  margin-right: 30px;
}
</style>
